import React, { Suspense, lazy } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import NoMatch from "./404";
import { useTranslation } from 'react-i18next';
import '../i18n';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const LazyCallback = lazy(() => import("./callback"));

export const App: React.FunctionComponent = () => {
  const query = useQuery();
  const { t, i18n } = useTranslation();
  return (
    <Suspense fallback={<></>} >
      <Switch>
        <Route path={"/contest/:cid/:tid/:sid"} component={lazy(() => import("./contest"))} />
        <Route path={"/contest/:cid/:tid"} component={lazy(() => import("./contest"))} />
        <Route exact path={"/landingpage/:cid"} component={lazy(() => import("./homepage"))} />
        {/* landing -> landingpage*/}
        <Route exact path={"/"} component={lazy(() => import("./homepage/list"))} />
        <Route exact path={"/competition"} component={lazy(() => import("./homepage/list"))} />
        <Route exact path={"/account"} component={lazy(() => import("./user"))} />
        <Route exact path={"/dataset"} component={lazy(() => import("./dataset"))} />
        <Route path={"/loginredirect"} render={() => <LazyCallback code={query.get("code")} redirectUrl={query.get("state")} />} />
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch >
    </Suspense >
  );
};
export default App;
