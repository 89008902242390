import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Http2ServerRequest } from 'http2';
import { HighlightSpanKind } from 'typescript';

export type LoginState = "guest" | "user" | "admin";
export interface IUserState {
  key: string;
  mode: number;
  user: LoginState;
  fail: string | null;
}

const initialState: IUserState = {
  key: "",
  user: "guest",
  mode: 3,
  fail: null,
}

export const UserSlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    changeMode: (state, action: PayloadAction<number>) => {
      state.mode = action.payload;
    },
    loginSuccess: (state, action: PayloadAction<string>) => {
      state.key = action.payload;
      state.user = "user"
    },
    loginAdmin: (state, action: PayloadAction<string>) => {
      state.key = action.payload;
      state.user = "user"
    },
    logout0: (state, action: PayloadAction<string|null>) => {
      state.key = "";
      state.user = "guest";

    },
    loginFailed: (state, action: PayloadAction<string | null>) => {
      state.key = "";
      state.user = "guest"
      state.fail = action.payload;
    }
  }
})

export const { changeMode, loginSuccess, loginAdmin, logout0, loginFailed } = UserSlice.actions
export default UserSlice.reducer;