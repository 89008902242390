import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { UserSlice } from './user';

// 合并多个reducer
const rootReducer = combineReducers({
  user: UserSlice.reducer,
})
const store = configureStore({
  reducer: rootReducer,
  // 可以添加自己的中间件,比如打印日志的
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware()],
  devTools: true,
});

// 获取全部store数据类型
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch
export default store;
