import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
		"赛道": "Tracks",
    "进行中":"Processing",
    "即将开始":"To begin",
    "已结束":"Ended",
    "奖励":"Award",
    "参赛人数":"Players",
    "比赛时间":"Date",
    "主办单位":"Hoster",
    "承办单位":"Organizer",
    "协办单位":"Co-Organizer",
    "协办及赞助单位":"Co-Organizer(s) and  Sponsor(s)",
    "赛题冠名单位":"Problem Sponsor",
    "数据支持单位":"Data Sponsor",
    "报名参赛后方可下载":"Please joint a competition first.",
    "下载数据":"Download",
    "已下载":"Downloaded",
    "次":"Times",
    "文件大小":"Size",
    "数据集":"Datasets",
    "更多数据":"More",
    "常设技术委员会":"PTC",
    "快来加入吧！":"JOIN NOW!",
    "立即加入":"Join",
    "您已登录，快去报名吧!":"Login Success!",
    "注册成功":"Register Success!",
    "注册失败":"Fail to register",
    "登出成功":"Logout",
    "首页":"Homepage",
    "竞赛":"Competitions",
    "注册":"Register",
    "登录":"Login",
    "个人信息":"Profile",
    "登出":"Logout",
    "管理员":"Admin",
    "用户名":"Username",
    "邮箱":"Email",
    "密码":"Password",
    "重复密码":"Reply Password",
    "我已经阅读":"I have read ",
    "并承诺遵守":" and accepted.",
    "参赛队伍":"No. of Teams",
    "提交次数":"Submit Times",
    "比赛奖励":"Award",
    "待定":"TBD",
    "鹏城大赛平台":"Homepage",
    "大赛列表":"Contest List",
    "文件提交":"Upload File",
    "从我的仓库提交":"From OpenI Git",
    "点击或拖拽文件以上传":"Click or Drag Here",
    "仓库文件路径":"Path of file in OpenI repo",
    "开源协议":"License",
    "提交":"Submit",
    "提交成功":"Submit Success",
    "提交失败":"Submit Failed",
    "确定":"Confirm",
    "请检查文件正确性并确认上传完毕":"Please be sure all files uploaded.",
    "取消":"cancel",
    "提交状态":"Submissions",
    "排队中":"Waiting",
    "评测中":"Judging",
    "已完成":"Finished",
    "评测失败":"Failed",
    "评测取消":"Cancel",
    "得分":"Score",
    "格式正确":"Correct",
    "提交时间":"Date",
    "下载文件":"Download",
    "文件列表":"File(s)",
    "评测信息":"Judgeinfo",
    "我的成绩":"My Submission(s)",
    "报名 组队":"Register & Team",
    "题目":"Problem",
    "排行":"Ranklist",
    "排行（未开放）":"Ranklist(closed)",
    "排名":"#",
    "队伍":"Team",
    "有效成绩提交时间":"Submit Time",
    "代码开源":"Open source",
    "切换语言":"切换语言",
    "关注公众号，了解更多":"Focus on NAIC",
    "实时报名人数":"Number of Registers",
    "实时报名团队":"Number of Teams",
    "分享":"Share",
    "距离本阶段结束还有":"Countdown of Current Stage",
    "天":"day(s)",
    "小时":"hour(s)",
    "分钟":"minute(s)",
    "本赛段已结束":"Finished",
    "队伍名称":"Team's name",
    "开源类型":"Open source",
    "不开源":"Close",
    "修改":"Edit",
    "队伍头像":"Team's Logo",
    "你可以在用户信息处修改你的头像，这是队伍的头像，仅可以在这里修改。":"Edit the logo of team here while edit logo of yourself in profile.",
    "点击按钮上传新头像并自动修改":"click the button to upload logo",
    "上传新头像":"Upload",
    "队伍成员":"Members",
    "成员账号":"User",
    "角色":"Role",
    "成员状态":"Status",
    "操作":"Options",
    "创始人":"Teamleader",
    "待审核":"Applicant",
    "开发者":"Member",
    "正常":"Normal",
    "申请加入":"Apply",
    "每一个队伍相应地在OpenI有一个队伍仓库，请尽情地使用吧。":"Your team's OpenI repo.",
    "拷贝链接":"Copy",
    "跳转至仓库":"Go",
    "离开队伍":"Quit",
    "退出/解散队伍":"Quit/Dissolve",
    "您将离开队伍，如果您是队长那么队伍将被解散":"You will quit the team.(If you are team manager,the team will be dissolved)",
    "启智开源":"Qi Zhi",
    "比赛报名":"Register",
    "我已经阅读并理解了平台相关规则和比赛规则及政策，现在报名比赛":"I have read rules and policy of the competition and planteform. ",
    "提交种类":"Submit by",
    "编辑队伍":"Edit Team",
    "队名":"Teamname",
    "（已结束）":"(Finished)",
    "（进行中）":"(Processing)",
    "（敬请期待）":"(Come soon)",
    "新建队伍":"Create team",
    "队伍列表":"Teamlist",
    "学校":"College",
    "队伍ID":"TeamID"
    }
  },
  zh: {
    translation: {
		"赛道": "赛道",
    "进行中":"进行中",
    "即将开始":"即将开始",
    "已结束":"已结束",
    "奖励":"奖励",
    "参赛人数":"参赛人数",
    "比赛时间":"比赛时间",
    "主办单位":"主办单位",
    "承办单位":"承办单位",
    "协办单位":"协办单位",
    "协办及赞助单位":"协办及赞助单位",
    "赛题冠名单位":"赛题冠名单位",
    "数据支持单位":"数据支持单位",
    "报名参赛后方可下载":"报名参赛后方可下载",
    "下载数据":"下载数据",
    "已下载":"已下载",
    "次":"次",
    "文件大小":"文件大小",
    "数据集":"数据集",
    "更多数据":"更多数据",
    "常设技术委员会":"常设技术委员会",
    "快来加入吧！":"快来加入吧！",
    "立即加入":"立即加入",
    "您已登录，快去报名吧!":"您已登录，快去报名吧!",
    "注册成功":"注册成功",
    "注册失败":"注册失败",
    "登出成功":"登出成功",
    "首页":"首页",
    "竞赛":"竞赛",
    "注册":"注册",
    "登录":"登录",
    "个人信息":"个人信息",
    "登出":"登出",
    "管理员":"管理员",
    "用户名":"用户名",
    "邮箱":"邮箱",
    "密码":"密码",
    "重复密码":"重复密码",
    "我已经阅读":"我已经阅读",
    "并承诺遵守":"并承诺遵守",
    "参赛队伍":"参赛队伍",
    "提交次数":"提交次数",
    "比赛奖励":"比赛奖励",
    "待定":"待定",
    "鹏城大赛平台":"鹏城大赛平台",
    "大赛列表":"大赛列表",
    "文件提交":"文件提交",
    "从我的仓库提交":"从我的仓库提交",
    "点击或拖拽文件以上传":"点击或拖拽文件以上传",
    "仓库文件路径":"仓库文件路径",
    "开源协议":"开源协议",
    "提交":"提交",
    "提交成功":"提交成功",
    "提交失败":"提交失败",
    "确定":"确定",
    "请检查文件正确性并确认上传完毕":"请检查文件正确性并确认上传完毕",
    "取消":"取消",
    "提交状态":"提交状态",
    "排队中":"排队中",
    "评测中":"评测中",
    "已完成":"已完成",
    "评测失败":"评测失败",
    "评测取消":"评测取消",
    "得分":"得分",
    "格式正确":"格式正确",
    "提交时间":"提交时间",
    "下载文件":"下载文件",
    "文件列表":"文件列表",
    "评测信息":"评测信息",
    "我的成绩":"我的成绩",
    "报名 组队":"报名 组队",
    "题目":"题目",
    "排行":"排行",
    "排行（未开放）":"排行（未开放）",
    "排名":"排名",
    "队伍":"队伍",
    "有效成绩提交时间":"有效成绩提交时间",
    "代码开源":"代码开源",
    "切换语言":"English Vision",
    "关注公众号，了解更多":"关注公众号，了解更多"
    }
  }
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "zh", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;